import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './login/auth.guard';

const routes: Routes = [

    {
    path: 'manager',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'owner',
    loadChildren: () => import('./owner/owner.module').then( m => m.OwnerPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'bookings/:date',
    loadChildren: () => import('./bookings/bookings.module').then( m => m.BookingsPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'inquiries',
    loadChildren: () => import('./inquiries/inquiries.module').then( m => m.InquiriesPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'calendar',
    loadChildren: () => import('./calendar/calendar.module').then( m => m.CalendarPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'cash',
    loadChildren: () => import('./cash/cash.module').then( m => m.CashPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'add-inquiry',
    loadChildren: () => import('./add-inquiry/add-inquiry.module').then( m => m.AddInquiryPageModule)
  },
  {
    path: 'add-booking',
    loadChildren: () => import('./add-booking/add-booking.module').then( m => m.AddBookingPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'view-inquiry/:id',
    loadChildren: () => import('./view-inquiry/view-inquiry.module').then( m => m.ViewInquiryPageModule)
  },
  {
    // canActivate: [AuthGuard],
    path: 'view-booking/:id',
    loadChildren: () => import('./view-booking/view-booking.module').then( m => m.ViewBookingPageModule)
  },
  {
    // canActivate: [AuthGuard],
    path: 'add-menu/:id',
    loadChildren: () => import('./menu/menu.module').then( m => m.MenuModule)
  },

  {
    canActivate: [AuthGuard],
    path: 'price-breakup',
    loadChildren: () => import('./price-breakup/price-breakup.module').then( m => m.PriceBreakupPageModule)
  },
  {
    path: 'print-preview',
    loadChildren: () => import('./print-preview/print-preview.module').then( m => m.PrintPreviewModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'add-payment-modal',
    loadChildren: () => import('./add-payment-modal/add-payment-modal.module').then( m => m.AddPaymentModalPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'stats',
    loadChildren: () => import('./stats/stats.module').then( m => m.StatsPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'transactions',
    loadChildren: () => import('./transactions/transactions.module').then( m => m.TransactionsPageModule)
  },
  {
    path: 'owner-dashboard',
    loadChildren: () => import('./owner-dashboard/owner-dashboard.module').then( m => m.OwnerDashboardPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'owner-bookings',
    loadChildren: () => import('./owner-bookings/owner-bookings.module').then( m => m.OwnerBookingsPageModule)
  },
  {    canActivate: [AuthGuard],
    path: 'bookings-date/:id/:start/:end',
    loadChildren: () => import('./bookings-date/bookings-date.module').then( m => m.BookingsDatePageModule)
  },
  {
    canActivate: [AuthGuard],

    path: '',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'menu-type/:id',
    loadChildren: () => import('./menu-type/menu-type.module').then( m => m.MenuTypePageModule)
  },
  {
    canActivate: [AuthGuard],

    path: 'prospectus/:id',
    loadChildren: () => import('./prospectus/prospectus.module').then( m => m.ProspectusPageModule)
  },
  {
    // canActivate: [AuthGuard],

    path: 'prospectus-page/:id',
    loadChildren: () => import('./prospectus-page/prospectus-page.module').then( m => m.ProspectusPagePageModule)
  },
  {
    canActivate: [AuthGuard],

    path: 'loader',
    loadChildren: () => import('./loader/loader.module').then( m => m.LoaderPageModule)
  },
  {
    canActivate: [AuthGuard],

    path: 'error',
    loadChildren: () => import('./error/error.module').then( m => m.ErrorPageModule)
  },
  {
    canActivate: [AuthGuard],

    path: 'print-preview/:id',
    loadChildren: () => import('./print-preview/print-preview.module').then( m => m.PrintPreviewModule)
  },
  {
    path: 'access',
    loadChildren: () => import('./access/access.module').then( m => m.AccessPageModule)
  },
  {
    path: 'add-review/:id',
    loadChildren: () => import('./add-review/add-review.module').then( m => m.AddReviewPageModule)
  },
  {
    path: 'reviews',
    loadChildren: () => import('./reviews/reviews.module').then( m => m.ReviewsPageModule)
  },
  {
    path: 'view-review/:id',
    loadChildren: () => import('./view-review/view-review.module').then( m => m.ViewReviewPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule)
  },



];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
