import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import * as moment from 'moment';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';
import {AlertController, ToastController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    loading = true;
    banquet = {
        floors: 4,
        menu: '',
        id: ''
    };
    floors = [];
    public initComplete = false;
    public bookingUpdate: BehaviorSubject<boolean>;
    public auth = new BehaviorSubject<Boolean>(null);
    public apiReady: boolean = false;

    public user = {
        login: false,
        name: '',
        banquet: '',
        userId: '',
        pin: 0,
        access: [],
        role: ''
    };
    calendarDate = new Date();


    public dateSession = {
        inquiry: {
            end: new Date(),
            start: new Date(),
            mode: 'all',
            type: 1,
            yesterday: moment().subtract(1, 'day').toDate(),
            closedFilter: undefined
        },
        booking: {
            end: moment().add(30, 'day').toDate(),
            start: new Date(),
            mode: 'all',
            type: 1,
            yesterday: moment().subtract(1, 'day').toDate(),

        },
        cash: {
            end: moment().add(30, 'day').toDate(),
            start: new Date(),
            mode: 'all',
            type: 1,
            yesterday: moment().subtract(1, 'day').toDate(),
        },
        // dashboard: {
        //   date: new Date(),
        //   end : new Date(),
        //   start: new Date(),
        //   mode : 'all',
        //   type: 1,
        //   yesterday : moment().subtract(1, 'day').toDate(),
        //   upcomingEnd:  moment().add(30, 'day').toDate(),
        //   upcomingStart:  moment().add(1, 'day').toDate(),
        // }


        ownerDashboard: {
            end: new Date(),
            start: new Date(),
            type: 1,
            yesterday: moment().subtract(1, 'day').toDate(),
        },
        ownerBooking: {
            end: new Date(),
            start: moment().toDate(),
            type: 1,
            yesterday: moment().subtract(1, 'day').toDate(),
            banquet: '0'

        },

    };


    constructor(private storage: Storage, public router: Router, private alert: AlertController, public toastCtrl: ToastController) {
        this.initUserData();
        this.bookingUpdate = new BehaviorSubject<boolean>(true);
    }


    public getBookingUpdate(): Observable<boolean> {
        return this.bookingUpdate.asObservable();
    }

    public async initUserData() {
        try {
            const user = await this.storage.get('user');
            this.user.userId = user.id;
            this.user.access = user.access;
            this.user.name = user.username;
            this.user.pin = user.pin;
            this.user.login = await this.storage.get('login');
            this.user.role = await this.storage.get('role');

            const role = await this.storage.get('role');

            if (role === 'manager') {
                const banquet = await this.storage.get('banquet');
                console.log('******', banquet);
                this.banquet = banquet;
                this.user.banquet = banquet.id;
                this.floors = _.range(banquet.floors);
                this.initComplete = true;
                console.log('this is banquet in util', this.banquet);
            }
            this.auth.next(this.user.login);
            this.apiReady = true;

        } catch (e) {
            console.log('An error occurred while loading user data', e);
            this.presentAlert('Error!!', 'Unable to get login information please login again', this.logout());
            this.auth.next(false);
        }

    }

    public async presentAlert(title, message, callBack) {
        const alert = await this.alert.create({
            header: title,
            message: message,
            buttons: [{
                text: 'Ok',
                handler: callBack
            }]
        });

        await alert.present();
    }

    public async presentAlertTwoButtons(title, message) {
        const alert = await this.alert.create({
            header: title,
            message: message,
            buttons: [{
                text: 'Yes',
                handler: (blah) => {
                    this.logout();
                }
            },
                {
                    text: 'Cancel'
                }]
        });

        await alert.present();
    }

    public checkUserAccess(path) {
        path = path.split('?')[0];
        // console.log('this is path', path);
        for (const item of this.user.access) {
            // console.log('this is item of access', item);
            if (item.path === path) {
                return item.access;
            }
        }

        return true;
    }


    public checkEditRights(remove) {
        if (remove) {
            const path = _.find(this.user.access, ['name', 'delete-booking']);
            return path.access;
        } else {
            const path = _.find(this.user.access, ['name', 'edit-booking']);
            return path.access;
        }

        return false;
    }

    public async logout() {
        try {
            await this.storage.remove('user');
            await this.storage.set('login', false);
            await this.storage.remove('banquet');
            this.router.navigateByUrl('/login');
        } catch (e) {
            console.log('An error occurred while loading user data', e);
        }
    }

    public getHumanDate(date) {
        return moment(date).format('MMMM DD, YYYY');
    }

    public getHumanShortDate(date) {
        return moment(date).format('MMMM DD');
    }


    public getNumberWithOrdinal(n) {
        const s = ['th', 'st', 'nd', 'rd'],
            v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
    }

    findCategoryNamebyId(id, array) {
        console.log('this is category id**********', id);
        console.log('this is category array', array);

        const category = _.find(array, {id});
        console.log('this is category', category);

        // console.log('this is category name', category);
        return category ? category.name : '';

    }

    async presentToast(msg: string) {
        const toast = await this.toastCtrl.create({
            color: 'dark',
            message: msg,
            duration: 2000
        });
        await toast.present();
    }

    formatCurrency(amount) {
        amount = amount.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'INR'
        });

        return amount;
    }

    getHalls(halls) {
        console.log('This is halls', halls);
        const hallsArray = halls.split(',');
        const newHalls = [];
        hallsArray.forEach(el => {
            el = Number(el) + 1;
            newHalls.push(el);
            console.log('El', el);
        });


        console.log('Halls array', hallsArray);
        return newHalls.toString();
    }

}
