import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Storage} from '@ionic/storage';
import {AppConstants} from '../util/AppConstants';
import {Observable} from 'rxjs';
import {Banquet} from '../models/banquet';
import {Inquiry} from '../models/Inquiry';
import {Extra} from '../models/Extra';
import {Menu} from '../models/Menu';
import {Booking} from '../models/Booking';
import {Food} from '../models/Food';
import {Transaction} from '../models/Transaction';
import * as moment from 'moment';
import {Moment} from 'moment';
import {Addon} from '../models/addon';
import {Category} from '../models/Category';
import {Dish} from '../models/Dish';
import {Activity} from '../models/Activity';
import {Data, data} from '../models/data';
import {Reviews} from '../models/reviews';

@Injectable({
  providedIn: 'root'
})
export class ApiService {


  private headers: HttpHeaders;
  public MAX_TRIES = 2;
  public API_READY = false;

  constructor(private http: HttpClient, private storage: Storage) {
    this.setToken();
  }

  public setToken(token?: string) {
    this.headers = new HttpHeaders();

    if (token) {
      this.headers = this.headers.set('Authorization', 'Bearer ' + token);
      this.API_READY = true;
    } else {
      this.storage.get('token').then(data => {
        console.log('Token found', data);
        this.headers = this.headers.set('Authorization', 'Bearer ' + data);
        this.API_READY = true;
      });
    }

  }

  apiRetry() {
    if (!this.API_READY) {
      setTimeout(() => {this.apiRetry.caller(); }, 1000);
      return;
    }
  }


  getInquiries(): Observable<Inquiry[]> {
    return this.http
        .get<Inquiry[]>(AppConstants.API_URLS.INQUIRIES + '?_limit=1000', {headers: this.headers});
  }

  getBookings(): Observable<Booking[]> {
    return this.http
        .get<Booking[]>(AppConstants.API_URLS.BOOKINGS + '?status_ne=Cancelled', {headers: this.headers});
  }
  getActivities(): Observable<Activity[]> {
    return this.http
        .get<Activity[]>(AppConstants.API_URLS.ACTIVITIES + '?_limit=50&_sort=date:DESC', {headers: this.headers});
  }

  getBookingsByBanquet(banquetId: string): Observable<Booking[]> {
    return this.http
        .get<Booking[]>(AppConstants.API_URLS.BOOKINGS + '?status_ne=Cancelled&_limit=5000&banquet=' + banquetId, {headers: this.headers});
  }

  getBookingsByBanquetCancelled(start: Date, end: Date, banquetId: string): Observable<Booking[]> {
    const startDate = moment(start).startOf('day');
    const endDate = end === undefined ? moment(start).endOf('day') : moment(end).endOf('day');
    return this.http
        .get<Booking[]>(AppConstants.API_URLS.BOOKINGS + '?status=Cancelled&_limit=5000&createdAt_gte=' + startDate.toISOString() + '&createdAt_lte=' + endDate.toISOString() + (banquetId ? '&banquet=' + banquetId : ''), {headers: this.headers});
  }


  getInquiriesByBanquet(banquetId: string): Observable<Inquiry[]> {
    return this.http
        .get<Inquiry[]>(AppConstants.API_URLS.INQUIRIES + '?_limit=5000&banquet=' + banquetId, {headers: this.headers});
  }

  getAddon(): Observable<Addon[]> {
    return this.http
        .get<Addon[]>(AppConstants.API_URLS.ADDON, {headers: this.headers});
  }

  getAddonByType(type , term , limit: number): Observable<Addon[]> {

    if (limit > 0) {
      return this.http
          .get<Addon[]>(AppConstants.API_URLS.ADDON + '?type=' + type + '&enable=true&name_contains=' + term  + '&_limit=' + limit, {headers: this.headers});

    } else {
      return this.http
          .get<Addon[]>(AppConstants.API_URLS.ADDON + '?type=' + type + '&enable=true&name_contains=' + term, {headers: this.headers});
    }
  }

  deleteBooking(id): Observable<Booking[]> {
    return this.http
        .delete<Booking[]>(AppConstants.API_URLS.BOOKINGS + '/' + id, {headers: this.headers});
  }

  getFoodById(id): Observable<Food[]> {
    return this.http
        .get<Food[]>(AppConstants.API_URLS.FOOD + '/' + id);
  }
  deleteFoodById(id): Observable<Food[]> {
    return this.http
        .delete<Food[]>(AppConstants.API_URLS.FOOD + '/' + id, {headers: this.headers});
  }

  getBookingsByMonth(date: Date): Observable<Booking[]> {
    const start = moment(date).startOf('month');
    const end = moment(date).endOf('month');
    return this.http
        .get<Booking[]>(AppConstants.API_URLS.BOOKINGS  + '?status_ne=Cancelled&date_gte=' + start + '&date_lte=' + end, {headers: this.headers});
  }



  getBookingsByMonthandBanquet(date: Date, id: string): Observable<Booking[]> {
    const start = moment(date).startOf('month');
    const end = moment(date).endOf('month');
    return this.http
        .get<Booking[]>(AppConstants.API_URLS.BOOKINGS  + '?status_ne=Cancelled&date_gte=' + start + '&date_lte=' + end + '&banquet=' + id + '&_limit=5000', {headers: this.headers});
  }

  getBookingsForRange(date: Date, endDate: Date, banquet): Observable<Booking[]> {
    const start =  moment(date).startOf('day');
    const end = endDate === undefined ? moment(start).endOf('day') : moment(endDate).endOf('day');


    if (banquet === '0') {
      return this.http
          .get<Booking[]>(AppConstants.API_URLS.BOOKINGS  + '?status_ne=Cancelled&_limit=5000&createdAt_gte=' + start + '&createdAt_lte=' + end, {headers: this.headers});
    } else {
      return this.http
          .get<Booking[]>(AppConstants.API_URLS.BOOKINGS  + '?status_ne=Cancelled&_limit=5000&createdAt_gte=' + start +
              '&createdAt_lte=' + end + '&banquet=' + banquet, {headers: this.headers});
    }

  }

  getReviewsForRange(date: Date, endDate: Date, banquet): Observable<Reviews[]> {
    const start = moment(date).startOf('day');
    const end = moment(endDate).endOf('day');

    if (banquet === '0') {
      return this.http
          .get<Reviews[]>(AppConstants.API_URLS.REVIEW  + '?createdAt_gte=' + start + '&createdAt_lte=' + end, {headers: this.headers});
    } else {
      return this.http
          .get<Reviews[]>(AppConstants.API_URLS.REVIEW  + '?createdAt_gte=' + start +
              '&createdAt_lte=' + end + '&banquet=' + banquet, {headers: this.headers});
    }

  }

  getBookingsDataForChart(start: Moment, end: Moment): Observable<Booking[]> {
    return this.http
        .get<Booking[]>(AppConstants.API_URLS.BOOKINGS  + '?status_ne=Cancelled&createdAt_gte=' + start + '&createdAt_lte=' + end, {headers: this.headers});
  }

  getInquiriesByMonth(date: Date): Observable<Inquiry[]> {
    const start = moment(date).startOf('month');
    const end = moment(date).endOf('month');
    return this.http
        .get<Inquiry[]>(AppConstants.API_URLS.INQUIRIES  + '?date_gte=' + start + '&date_lte=' + end, {headers: this.headers});
  }

    getTransactionsByMonthAndBanquet(date: Date, banquetId: string): Observable<Transaction[]> {
    const start = moment(date).startOf('month');
    const end = moment(date).endOf('month');
    return this.http
        .get<Transaction[]>(AppConstants.API_URLS.TRANSACTIONS  + '?date_gte=' + start + '&date_lte=' + end + '&_limit=5000&banquet=' + banquetId, {headers: this.headers});
  }

  getExtras(): Observable<Extra[]> {
    return this.http
        .get<Extra[]>(AppConstants.API_URLS.EXTRAS, {headers: this.headers});
  }

  getMenu(): Observable<Menu[]> {
    return this.http
        .get<Menu[]>(AppConstants.API_URLS.MENU);
  }
  getMenuByType(type): Observable<Menu[]> {
    return this.http
        .get<Menu[]>(AppConstants.API_URLS.MENU + '?type=' + type);
  }
  getMenuByTypeAndBanquet(type, id): Observable<Menu[]> {
    return this.http
        .get<Menu[]>(AppConstants.API_URLS.MENU + '?banquets=' + id +  '&type=' + type);
  }
  getMenuByBanquet(id): Observable<Menu[]> {
    return this.http
        .get<Menu[]>(AppConstants.API_URLS.MENU + '?banquets=' + id +  '&type=1&type=2');
  }

  getBooking(id): Observable<Booking> {
    return this.http
        .get<Booking>(AppConstants.API_URLS.BOOKINGS + '/' + id, {headers: this.headers});
  }


  postInquiry(body): Observable<Inquiry> {
    return this.http
        .post<Inquiry>(AppConstants.API_URLS.INQUIRIES, body, {headers: this.headers});
  }


  postReview(body): Observable<Reviews> {
    return this.http
        .post<Reviews>(AppConstants.API_URLS.REVIEW, body);
  }


  getReviewByDate(start: Date, end: Date, banquetId: string): Observable<Reviews[]> {
    const startDate = moment(start).startOf('day');
    const endDate = end === undefined ? moment(start).endOf('day') : moment(end).endOf('day');
    return this.http
        .get<Reviews[]>(AppConstants.API_URLS.REVIEW + '?createdAt_gte=' + startDate.toISOString() +
            '&createdAt_lte=' + endDate.toISOString() + (banquetId ? '&banquet=' + banquetId : ''));
  }


  getReviewsByBanquet(banquetId: string): Observable<Reviews[]> {
    return this.http
        .get<Reviews[]>(AppConstants.API_URLS.REVIEW + '?banquet=' + banquetId);
  }

  getReviewsById(id: string): Observable<Reviews> {
    return this.http
        .get<Reviews>(AppConstants.API_URLS.REVIEW + '/' + id);
  }


  postActivity(body): Observable<Activity> {
    return this.http
        .post<Activity>(AppConstants.API_URLS.ACTIVITIES, body, {headers: this.headers});
  }

  postFood(body): Observable<Food> {
    return this.http
        .post<Food>(AppConstants.API_URLS.FOOD, body);
  }

  postTransactions(body): Observable<Transaction> {
    return this.http
        .post<Transaction>(AppConstants.API_URLS.TRANSACTIONS, body, {headers: this.headers});
  }

  getTransactions(): Observable<Transaction[]> {
    return this.http
        .get<Transaction[]>(AppConstants.API_URLS.TRANSACTIONS, {headers: this.headers});
  }

  getTransactionsByBooking(bookingId: string): Observable<Transaction[]> {
    return this.http
        .get<Transaction[]>(AppConstants.API_URLS.TRANSACTIONS + '?booking=' + bookingId + '&_limit=5000', {headers: this.headers});
  }

  getTransactionsByMode(mode): Observable<Transaction[]> {
    return this.http
        .get<Transaction[]>(AppConstants.API_URLS.TRANSACTIONS + '?mode=' + mode, {headers: this.headers});
  }

  getTransactionsByDate(start: Date, end: Date): Observable<Transaction[]> {
    const startDate = moment(start).startOf('day');
    const endDate = end === undefined ? moment(start).endOf('day') : moment(end).endOf('day');
    return this.http
        .get<Transaction[]>(AppConstants.API_URLS.TRANSACTIONS + '?date_gte=' + startDate.toISOString() +
            '&date_lte=' + endDate.toISOString(), {headers: this.headers});
  }

  createBooking(body): Observable<Booking> {
    console.log('Req URL : ', AppConstants.API_URLS.BOOKINGS, 'Headers', this.headers);
    return this.http
        .post<Booking>(AppConstants.API_URLS.BOOKINGS, body, {headers: this.headers});
  }

    getInquiry(inquiryId: string): Observable<Inquiry> {
      return this.http
          .get<Inquiry>(AppConstants.API_URLS.INQUIRIES + '/' + inquiryId, {headers: this.headers});
    }

    getExtraDish(): Observable<Data[]> {
      return this.http
          .get<Data[]>(AppConstants.API_URLS.DATA, {headers: this.headers});
    }

    getExtraDishByName(name): Observable<Data> {
      return this.http
          .get<Data>(AppConstants.API_URLS.DATA + '?name=' + name, {headers: this.headers});
    }

  getBanquets(): Observable<Banquet[]> {
    return this.http
        .get<Banquet[]>(AppConstants.API_URLS.BANQUETS + '?enable=true', {headers: this.headers});
  }

    updateBooking(body, id) {
      return this.http
          .put<Booking>(AppConstants.API_URLS.BOOKINGS + '/' + id, body, {headers: this.headers});
    }

    updateInquiry(body, id) {
      return this.http
          .put<Inquiry>(AppConstants.API_URLS.INQUIRIES + '/' + id, body, {headers: this.headers});
    }

    updateTransaction(body, id) {
      return this.http
          .put<Inquiry>(AppConstants.API_URLS.TRANSACTIONS + '/' + id, body, {headers: this.headers});
    }


  getBookingsByDateAndBanquet(start: Date, end: Date, banquetId: string): Observable<Booking[]> {
    const startDate = moment(start).startOf('day');
    const endDate = end === undefined ? moment(start).endOf('day') : moment(end).endOf('day');
    return this.http
        .get<Booking[]>(AppConstants.API_URLS.BOOKINGS + '?status_ne=Cancelled&_limit=5000&date_gte=' + startDate.toISOString() +
            '&date_lte=' + endDate.toISOString() + (banquetId ? '&banquet=' + banquetId : ''), {headers: this.headers});
  }

  getBookingsByDate(start: Date, end: Date): Observable<Booking[]> {
    const startDate = moment(start).startOf('day');
    const endDate = end === undefined ? moment(start).endOf('day') : moment(end).endOf('day');
    return this.http
        .get<Booking[]>(AppConstants.API_URLS.BOOKINGS + '?status_ne=Cancelled&date_gte=' + startDate.toISOString() +
            '&date_lte=' + endDate.toISOString(), {headers: this.headers});
  }

  getBookingsByBookedDate(start: Date, end: Date, banquetId: string): Observable<Booking[]> {

    const startDate = moment(start).startOf('day');
    const endDate = end === undefined ? moment(start).endOf('day') : moment(end).endOf('day');
    console.log('this is start date', startDate);
    console.log('this is end date', endDate);
    console.log('this is banquet Id', banquetId);
    return this.http
        .get<Booking[]>(AppConstants.API_URLS.BOOKINGS + '?status_ne=Cancelled&_limit=5000&createdAt_gte=' + startDate.toISOString() +
            '&createdAt_lte=' + endDate.toISOString() + (banquetId ? '&banquet=' + banquetId : ''), {headers: this.headers});

  }

  getInquiriesByDateAndBanquet(start: Date, end: Date, banquetId: string): Observable<Inquiry[]> {
    const startDate = moment(start).startOf('day');
    const endDate = end === undefined ? moment(start).endOf('day') : moment(end).endOf('day');

    return this.http
        .get<Inquiry[]>(AppConstants.API_URLS.INQUIRIES + '?createdAt_gte=' + startDate.toISOString() +
            '&_limit=5000&createdAt_lte=' + endDate.toISOString() + (banquetId ? '&banquet=' + banquetId : ''), {headers: this.headers});

  }

  getClosedInquiriesByDateAndBanquet(start: Date, end: Date, banquetId: string): Observable<Inquiry[]> {
    const startDate = moment(start).startOf('day');
    const endDate = end === undefined ? moment(start).endOf('day') : moment(end).endOf('day');

    return this.http
        .get<Inquiry[]>(AppConstants.API_URLS.INQUIRIES + '?updatedAt_gte=' + startDate.toISOString() +
            '&_limit=5000&updatedAt_lte=' + endDate.toISOString() + (banquetId ? '&banquet=' + banquetId : ''), {headers: this.headers});

  }

    getInquiriesByBookedDate(start: Date, end: Date, banquetId: string): Observable<Inquiry[]> {

    const startDate = moment(start).startOf('day');
    const endDate = end === undefined ? moment(start).endOf('day') : moment(end).endOf('day');

    return this.http
        .get<Inquiry[]>(AppConstants.API_URLS.INQUIRIES + '?createdAt_gte=' + startDate.toISOString() +
            '&_limit=5000&createdAt_lte=' + endDate.toISOString() + (banquetId ? '&banquet=' + banquetId : ''), {headers: this.headers});

  }

  getDishesByCategory(categoryId): Observable<Dish[]> {
    return this.http.get<Dish[]>(AppConstants.API_URLS.DISHES + '?_sort=sort_order:ASC&categories=' + categoryId);
  }

  getCategoriesByMenu(menuId): Observable<Category[]> {
    return this.http.get<Category[]>(AppConstants.API_URLS.CATEGORIES + '?_sort=sort_order:ASC&menus=' + menuId);
  }


  getCategories(): Observable<Category[]> {
    return this.http.get<Category[]>(AppConstants.API_URLS.CATEGORIES + '?_limit=5000&_sort=sort_order:ASC');
  }


  getTransactionsByBanquet(banquetId: string) {
    return this.http
        .get<Transaction[]>(AppConstants.API_URLS.TRANSACTIONS + '?_limit=5000&banquet=' + banquetId, {headers: this.headers});
  }

  getTransactionsByDateAndBanquet(start: Date, end: Date, banquetId: string) {
    const startDate = moment(start).startOf('day');
    const endDate = end === undefined ? moment(start).endOf('day') : moment(end).endOf('day');
    return this.http
        .get<Transaction[]>(AppConstants.API_URLS.TRANSACTIONS + '?date_gte=' + startDate.toISOString() +
            '&date_lte=' + endDate.toISOString() + '&banquet=' + banquetId, {headers: this.headers});
  }
}
