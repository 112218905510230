export class AppConstants {
    public static BASE_URL = "https://api.thesandoz.in";
    public static APP_URL = "https://banquets.thesandoz.in";
    public static NATIVE_DEVICE = false;
    public static API_URLS = {
        LOGIN: AppConstants.BASE_URL + "/auth/local",
        INQUIRIES: AppConstants.BASE_URL + "/inquiries",
        DATA: AppConstants.BASE_URL + "/data",
        ACTIVITIES: AppConstants.BASE_URL + "/activities",
        TRANSACTIONS: AppConstants.BASE_URL + "/transactions",
        BOOKINGS: AppConstants.BASE_URL + "/bookings",
        ADDON: AppConstants.BASE_URL + "/addons",
        EXTRAS: AppConstants.BASE_URL + "/extras",
        MENU: AppConstants.BASE_URL + "/menus",
        FOOD: AppConstants.BASE_URL + "/foods",
        DISHES: AppConstants.BASE_URL + "/dishes",
        CATEGORIES: AppConstants.BASE_URL + "/categories",
        BANQUETS: AppConstants.BASE_URL + "/banquets",
        REVIEW: AppConstants.BASE_URL + "/reviews",

    }
    public  static inquiryToBooking;
    public  static menuType;
    public static date:Date;
    public static time = '';
    public static floor = '';

}
